import React from 'react';
import '../../Pages/App/App.css';

function Circles() {
	return (
		<div className="circles" style={{'z-index': -100}}>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </div>
	);
}

export default Circles;